import React from "react";
import Editor from "./components/editor";
import ReactMarkdown from "react-markdown";
import "./App.scss";

const App = () => {
  const [markdown, setMarkdown] = React.useState<string>();

  const updateMarkdown = (input: string): void => {
    setMarkdown(input);
  };
  return (
    <div className="App">
      <Editor changeFunction={updateMarkdown} />
      <div className="ReactMarkdown_container">
        <ReactMarkdown source={markdown} className="ReactMarkdown" />
      </div>
    </div>
  );
};
export default App;
