import React from "react";
import "./index.scss";

interface Props {
  changeFunction: (input: string) => void;
}

const Editor: React.FC<Props> = (props) => {
  const [markdown, setMarkdown] = React.useState("");

  const updateMarkdown = (input: string) => {
    setMarkdown(input);
    props.changeFunction(input);
  };

  return (
    <div className="editor">
      <textarea
        className="editor_textarea"
        value={markdown}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          //setMarkdown(e.target.value);
          updateMarkdown(e.target.value);
        }}
        placeholder={"# Write your markdown"}
      />
    </div>
  );
};

export default Editor;
